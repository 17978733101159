// islands/ServerList.tsx
import Accordion from "../Accordion.tsx";
import ServerListElements from "./ServerListElements.tsx";
import { selectedServerID, allAccordionOpen } from '../globalSignals.ts';

export default function ServerList() {
  const handleServerSelect = (serverId: string | null) => {
    selectedServerID.value = serverId;
  };

  return (
    <div class="container mx-auto p-2">
      <Accordion title="Available FHIR Servers" allOpen={allAccordionOpen.value}>
        <ServerListElements 
          selectedServerID={selectedServerID.value}
          setSelectedServerID={handleServerSelect}
        />
      </Accordion>
    </div>
  );
}
